import React from "react";
import Navbar from "./components/NavBar";
import Footer from "./components/Footer";
import SolutionCard, { SolutionCardProps } from "./components/SolutionCard";

const solutionList: SolutionCardProps[] = [
  {
    title: "Marketing Mix Model",
    imgSrc: "/images/5.png",
    useCases: [
      "Machine learning model using aggregate data to predict incremental impact of different channels historically and make predictions about different, possible scenarios.",
    ],
  },
  {
    title: "Bid Optimizer",
    imgSrc: "/images/13.png",
    useCases: [
      "In-flight, in-platform optimizations based on machine learning analysis of customer incremental value derived from consented 1P data.",
    ],
  },
  {
    title: "Geo-Measurement",
    imgSrc: "/images/11.png",
    useCases: [
      "Solution to conduct x-channel incremental lift experiments with aggregated data and synthetic controls.",
    ],
  },
  {
    title: "Multi-touch Attribution",
    imgSrc: "/images/15.png",
    useCases: [
      "Digital-only solution for attributing publisher and channel-level contributions to a conversion. AI + aggregated analyses help fill data gaps.",
    ],
  },
];

const Solutions: React.FC = () => {
  return (
    <>
      <Navbar />
      <div className={"text-center font-extrabold text-4xl text-gray-600 mt-8"}>
        Our Solutions
      </div>
      <div className={"flex flex-wrap justify-center"}>
        {solutionList.map((solution, index) => (
          <div key={index} className={"m-12"}>
            <SolutionCard
              imgSrc={solution.imgSrc}
              title={index + 1 + ". " + solution.title}
              useCases={solution.useCases}
            />
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default Solutions;
