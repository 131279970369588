import React from 'react';
import Navbar from "./components/NavBar";
import Footer from "./components/Footer";
import {Disc} from 'react-feather';

const points = [
    'Help small and large business leverage the power of technology',
    'Use business as a vehicle of creation & progress',
    'Raise the consciousness of all people'
];

const motivationList = [
    {
        title: "Compassion",
        subtitle: "We lead with compassion",
    },
    {
        title: "Dharma",
        subtitle: "We do the right thing",
    },
    {
        title: "Work",
        subtitle: "We do hard things",
    },
    {
        title: "Courage",
        subtitle: "We chase secrets",
    },
];

const hypothesisList = [
    'AI & automation are now eating analytics services',
    'Privacy is everyone’s concern',
    'Trust and business must go hand in hand',
    'Every person is a content creator and a consumer',
    'Every business is a media company, a publisher, and an advertiser',
    'AI is not the answer to everything - Context is king',
    'The gap between the data haves and the data have-nots is increasing',
    'There is a huge difference between academic research and its application in business',
    'Private internet is here to stay',
    'Privacy, AI, and APIs are the present and the future of Advertising'
];

const Values: React.FC = () => {
    return (
        <>
            <Navbar/>
            <div className={"text-left text-2xl text-gray-500 font-extrabold my-12 mx-8 sm:mx-40"}>
            Our vision is to make a user adrsta (unseen) on the internet “for the purpose of digital adverting”.
            </div>
            <div className={"text-left text-2xl text-gray-500 font-extrabold my-12 mx-8 sm:mx-40"}>
                We are motivated by a deep desire to:
            </div>
            <ul className={"text-left text-2xl text-gray-500 font-extrabold my-12 mx-8 sm:mx-40 text-secondary"}>
                {points.map((point, index) => (
                    <li key={index} className={"font-bold mb-4 flex items-center"}>
                        <Disc size={"18"}/> &nbsp; {point}
                    </li>
                ))}
            </ul>
            <ul className={"text-center mt-24 mx-8 sm:mx-40 flex justify-between sm:flex-row flex-col"}>
                {motivationList.map((motivation, index) => (
                    <li key={index} className={"font-bold mb-12"}>
                        <div>
                            <div className={"text-primary font-extrabold text-4xl"}>{motivation.title}</div>
                            <div className={"text text-gray-400 mt-2"}>{motivation.subtitle}</div>
                        </div>
                    </li>
                ))}
            </ul>
            <div className={"my-8 border-t-2 border-gray-100"}></div>
            <div className={"text-left my-12 mx-8 sm:mx-40 text-2xl font-extrabold text-gray-500"}>Our 10 hypotheses
            </div>
            <ul className={"text-left mx-8 sm:mx-40 mb-24"}>
                {hypothesisList.map((hypothesis, index) => (
                    <li key={index} className={"font-bold text-secondary text-2xl mb-4"}>
                        {index + 1}.&nbsp; {hypothesis}
                    </li>
                ))}
            </ul>
            <Footer/>
        </>
    );
};

export default Values;
